nav {
  padding: .5rem;
  width: 100%;
  color: var(--primary-color);
  background-color: var(--primary-bgcolor);   
}

nav a {
  text-decoration: none;
}

nav.shadow {
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.2); 
}

.nav-logo header {  
  font-size: 2rem;
  text-decoration: none;
  color: var(--primary-color); 
}

.nav-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;  
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: var(--primary-color);
  font-family: 'Amatic SC', cursive;
  font-size: 1.5rem;
  padding: 0 .25rem;
  transition: .3s;
}

.nav-link:hover {
  text-decoration: underline;  
}

@media(min-width: 600px) {
  .nav-logo {
    display: flex;
    align-items: center;    
  }

  .nav-logo img {
    margin-right: .5rem;   
  }
}

@media (min-width: 900px) { 
  nav {
    padding: 1rem;      
  }

  .nav-link {
    color: var(--primary-color);
    font-size: 1.75rem; 
    padding: 0 .5rem;
    transition: .3s;
  }  
}