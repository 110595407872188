footer {
  padding: 2rem 0; 
}

.footer-wrapper {
  display: flex;
  color: var(--primary-color);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%; 
}

.footer-wrapper a {
  color: white;
}

.footer-wrapper div > span {
  color: #ffb1be;
}

.footer-social {
  display: flex;
  justify-content: center;
}

@media (min-width: 900px) {
  .footer-wrapper {
    display: flex;
    color: var(--primary-color);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
  }
}