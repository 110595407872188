.hero {
  height: 100%;
  width: 100%;
  position: relative;
}

.hero-image {  
  height: 100%;
  width: 100%;
  object-fit: cover;  
}

.hero-content p {
  width: 300px;
}

.hero-content {
  /* background-color: var(--primary-bgcolor); */
  z-index: 1; 
  position: absolute;
  top: 20%;
  left: 20%;
  padding: .5rem;
  text-align: center;
  margin: 0 auto;
  width: calc(100vw - 40%); 
  color: var(--primary-color);  
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 600px) { 
  .hero-content {
    top: 10%;
    left: 20%;    
    color: var(--secondary-color);
    background-color: unset;
  }
}

@media (min-width: 720px) { 
  .hero-content {    
    top: 25%;
  }
}


@media (min-width: 900px) { 
  .hero-content {
    top: 20%;
    left: 25%;
    width: calc(100vw - 50%); 
  }
}

@media (min-width: 1200px) { 
  .hero-content {    
  }
}