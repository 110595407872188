.about-layout {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 900px) {
  .about-layout {
    grid-gap: .5rem;
    grid-template-columns: 1fr 1fr;
  }

  .about-layout > div {
    padding: 0 .5rem;
    align-self: center;
  }
}