.contact-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100%;
  grid-template-columns: 1fr 1fr;
}

.contact-form {
  color: var(--secondary-color);
  background-color: var(--secondary-bgcolor);
}

.contact-links li {
  border-bottom: 1px solid gold;
  padding: 0;
}

.contact-links li span {
  color: var(--secondary-color);
  width: 64px;
  height: 100%;
  padding: .5rem;
  background-color: gold;
}

.contact-links li a {
  color: var(--primary-color);
  padding: .5rem 0;
}

.contact-info {
  padding: 0;
}

.contact-layout > form, .contact-layout > div {
  width: 100%;
}

@media (min-width: 900px){
  .contact-layout {
    flex-direction: row;
    align-content: center;
    min-height: 90%;
  }

  .contact-layout > form, .contact-layout > div {
    width: 50%;
    padding: 1rem;
  }
}