.subtemple-layout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
}

.subtemple-layout > div {  
  display: flex;
  flex-direction: column;
  justify-content: center;   
  align-items: center; 
}

.subtemple-layout > div > img {
  width: 100%;
  height: 100%;  
  padding: 0;
  margin: 2em 0;
}

.subtemple-layout > div > p {
  text-align: center;
}

.subtemple-layout > div:first-child > .actions {
  margin: .25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;    
}

@media (min-width: 900px) {
  .subtemple-layout {
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }

  .subtemple-layout > div {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;  
  }

  .subtemple-layout > div > img {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 1rem;
  }

  .subtemple-layout > div > p {
    text-align: left;
  }

  .subtemple-layout > div:first-child {
    display: flex;
    flex-direction: column;    
  }

  .subtemple-layout > div:first-child > .actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;    
  }

  .subtemple-layout > div:first-child > .actions a {
    margin-right: .5rem;    
  }

  
}