* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: var(--primary-color);
  background-color: var(--primary-bgcolor);
}

html, body, div#root, main {
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
header {
  font-family: 'Amatic SC', cursive;
  font-weight: bold;
  margin: 0;
}

/* Layouts */
.gallery-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
}

.gallery-layout img {
  object-fit: cover;
}

.contact-section {
  background-color: white;
  color: #222;
  padding: 1rem 0;
}

.contact-section h2 {  
  color: #222;
}

@media (min-width: 900px) {
  .gallery-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .hide-mobile {
    display: none;
  }
}

@media (max-width: 320px) {
  a[href="/gallery"] {
    display: none;
  }
}

.hidden {
  opacity: 0;
}

.fade-in {
  animation: fadein .3s forwards;
}

.fade-out {
  animation: fadeout .3s forwards;
}

.fade-slide-in {
  animation: fadeslidein .3s forwards;
}

@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeout {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeslidein {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}